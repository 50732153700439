import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const ArrowRightIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      size={size}
      fill="none"
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M3.334 8h9.333M8 3.333L12.667 8 8 12.667"
        stroke={color || 'currentColor'}
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseSvgIcon>
  )
}
