import * as D from 'date-fns'
import * as R from 'ramda'
import { Body } from 'components/design-system/type-system'
import { Flex } from 'components/design-system'

// Day of the month when we run payroll
const PAYDAY_DATE = 10

const MONTH_EMOJIS = [
  '⛄', // January
  '🏂', // February
  '🌲', // March
  '🦔', // April
  '🐝', // May
  '☀️', // June
  '🌴', // July
  '🏄', // August
  '🍂', // September
  '🎃', // October
  '🥧', // November
  '🎅', // December
]

export const getMonthEmoji = month => MONTH_EMOJIS[D.getMonth(month)]

const parseSafeDate = dateStr => new Date(parseInt(dateStr))

const isDate = v => v instanceof Date

const isNilOrDate = v => R.isNil(v) || isDate(v)

// TODO use parseNaiveDate. need to pass naive dates from server in format '2019-04-28'
export const parseDate = R.unless(
  isNilOrDate,
  R.pipe(parseSafeDate, date => D.subHours(date, getLocalUtcOffset(date)))
)

export const parseDateTime = R.unless(isNilOrDate, parseSafeDate)

export const parseDateTimeWithUtcOffset = ({ datetime, utcOffset }) => {
  if (R.isNil(datetime) || R.isNil(utcOffset)) return null

  const netUtcUffset = utcOffset - (getLocalUtcOffset() - getDstOffset())

  return D.addHours(parseSafeDate(datetime), netUtcUffset)
}

export const applyUtcOffsetDifference = (date, utcOffset) => {
  const netUtcUffset = utcOffset - (getLocalUtcOffset() - getDstOffset())

  return D.subHours(date, netUtcUffset)
}

export const parseNaiveDate = R.unless(
  isNilOrDate,
  R.pipe(
    date => new Date(date),
    date => D.subHours(date, getLocalUtcOffset(date))
  )
)

const stdTimezoneOffset = function () {
  const thisYear = new Date().getFullYear()
  var jan = new Date(thisYear, 0, 1)
  var jul = new Date(thisYear, 6, 1)

  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
}

export const getDstOffset = () => {
  return new Date().getTimezoneOffset() < stdTimezoneOffset() ? 1 : 0
}

export const getLocalUtcOffset = (date = new Date()) => {
  return -(date.getTimezoneOffset() / 60)
}

export const applyUtcOffset = (date, utcOffset) => {
  return D.addHours(date, utcOffset - getLocalUtcOffset() + getDstOffset())
}

export const stringifyDateTime = R.unless(R.isNil, date =>
  date.getTime().toString()
)

export const stringifyDate = R.unless(R.isNil, date =>
  D.format(date, 'YYYY-MM-DD')
)

export const getDateAsUtc = date =>
  new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
  )

export const formatDuration = date => {
  const today = new Date()
  let marker = date

  const years = D.differenceInYears(today, marker)
  marker = D.addYears(marker, years)

  const months = D.differenceInMonths(today, marker)
  marker = D.addMonths(marker, months)

  const days = D.differenceInDays(today, marker)

  let str = years ? `${years} years, ` : ''
  if (months) str = `${str}${months} months, `

  return `${str}${days} days`
}

export const getFormattedYear = () => D.format(new Date(), 'YYYY')

export const getAdjustedUtcOffset = () => {
  return Math.floor(getLocalUtcOffset() - getDstOffset())
}

export const parseUtcSeconds = R.unless(
  R.isNil,
  R.pipe(
    n => parseInt(n),
    n => {
      const d = new Date(0)
      d.setUTCSeconds(n)

      return d
    }
  )
)

export const formatTime = date => D.format(date, 'h:mm A')

const Timestamp = ({ date, time, wrapperClassName, timestampClassName }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      className={wrapperClassName}
      gap={0.25}
      mb={0.5}>
      <Body
        className={timestampClassName}
        color="textSecondary"
        size="tiny"
        typeSystem="desktop"
        weight="bold">
        {date}
      </Body>
      <Body
        className={timestampClassName}
        color="textSecondary"
        size="tiny"
        typeSystem="desktop">
        {time}
      </Body>
    </Flex>
  )
}

export const renderTimestampWithClassNames = ({
  wrapperClassName,
  timestampClassName,
}) =>
  R.cond([
    [
      D.isToday,
      date => (
        <Timestamp
          wrapperClassName={wrapperClassName}
          timestampClassName={timestampClassName}
          date="Today"
          time={formatTime(date)}
        />
      ),
    ], // ex: Today 2:35pm
    [
      D.isYesterday,
      date => (
        <Timestamp
          wrapperClassName={wrapperClassName}
          timestampClassName={timestampClassName}
          date="Yesterday"
          time={formatTime(date)}
        />
      ),
    ], // ex: Yesterday 6:45am
    [
      D.isThisWeek,
      date => (
        <Timestamp
          wrapperClassName={wrapperClassName}
          timestampClassName={timestampClassName}
          date={`${D.format(date, 'dddd')} `}
          time={formatTime(date)}
        />
      ),
    ], // ex: Sunday 6:45am
    [
      date => !D.isSameYear(date, new Date()),
      date => (
        <Timestamp
          wrapperClassName={wrapperClassName}
          timestampClassName={timestampClassName}
          date={`${D.format(date, 'ddd, MMM D, YYYY')} `}
          time={formatTime(date)}
        />
      ),
    ], // ex: Wed, May 8, 2022, 8:45am
    [
      R.T,
      date => (
        <Timestamp
          wrapperClassName={wrapperClassName}
          timestampClassName={timestampClassName}
          date={`${D.format(date, 'ddd, MMM D')} `}
          time={formatTime(date)}
        />
      ),
    ], // ex: Wed, May 8, 8:45am
  ])

export const localAbbreviatedTZ = date => {
  const parts = new Intl.DateTimeFormat([], {
    timeZoneName: 'short',
    month: '2-digit',
  }).formatToParts(date ?? new Date())
  const tz = R.find(x => x.type === 'timeZoneName', parts)
  return tz.value
}

/**
 * Utility used to determine if it is pay day
 * Since the invoice logic uses Pacific Time for payday,
 * we use that here too
 * @returns {boolean}
 */
export const isPayDay = () => {
  const todayInPT = new Date(
    new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })
  )
  const todaysDatePT = todayInPT.getDate()
  return todaysDatePT === PAYDAY_DATE
}

export const renderPrettyTimestamp = R.cond([
  [D.isToday, date => 'Today'],
  [D.isYesterday, date => 'Yesterday'],
  [D.isThisWeek, date => D.format(date, 'dddd')],
  [R.T, date => D.format(date, 'ddd, MMM D')],
])
