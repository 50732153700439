import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const PlusIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.667 7.333h-4v-4a.667.667 0 00-1.334 0v4h-4a.667.667 0 000 1.334h4v4a.667.667 0 001.334 0v-4h4a.667.667 0 100-1.334z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
