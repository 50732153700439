import React, { useMemo, useState } from 'react'
import cn from 'classnames'

import {
  Button,
  ButtonProps,
  ButtonIconSizeLookup,
  ButtonSizes,
} from 'components/design-system/button'
import {
  CheckmarkIcon,
  ExclamationTriangleIcon,
} from 'components/design-system/kickoff-icons'
import { Hidden } from 'components/design-system/hidden'
import { HelperText } from 'components/design-system/inputs'

import s from './styles.module.css'
import { sleep } from 'utilities/promises'
import { IconSizes } from '../kickoff-icons/base-svg-icon'

export interface ProgressButtonProps extends ButtonProps {
  /**
   * Optional CSS classname to pass to the root of the component
   */
  buttonRootClassName?: string
  /**
   * Optional property indicating that the button should be
   * rendered in a completed state. A checkmark indicator will
   * be displayed next to the button text
   */
  completed?: boolean
  /**
   * Optional override for the button text when the button is
   * in a completed state. Defaults to "Saved"
   */
  completedText?: string
  /**
   * Optional property indicating that the button should be
   * rendered in an error state. A checkmark indicator will
   * be displayed next to the button text
   */
  error?: boolean
  /**
   * Optional override for the button text when the button is
   * in an error state. Defaults to "Error"
   */
  errorText?: string
  /**
   * Optional property indicating that the button should be
   * rendered in a pending state. A spinning indicator will
   * be displayed next to the button text
   */
  pending?: boolean
  /**
   * Optional override for the button text when the button is
   * in a pending state. Defaults to "Loading"
   */
  pendingText?: string
}

/**
 * Lookup object that maps loading indicator sizes to the
 * proper icon size based on the design system specs
 * Small and medium sized buttons use the small icon
 * size while the large sized button uses the medium
 * icon size
 */
const loadingIconSizeLookup = {
  small: 16,
  medium: 16,
  large: 20,
}

/**
 * Component used to render a loading spinner within the
 * button when the button is in a loading state
 */
export const LoadingIcon = ({ size }: { size: ButtonSizes }) => {
  const sizeValue = loadingIconSizeLookup[size]
  const halfSize = sizeValue / 2
  const radius = (sizeValue - 2) / 2
  return (
    <svg
      className={s.loadingIcon}
      viewBox={`0 0 ${sizeValue} ${sizeValue}}`}
      xmlns="http://www.w3.org/2000/svg"
      width={sizeValue}
      height={sizeValue}>
      <circle
        className={s.loadingIconCircle}
        cx={halfSize}
        cy={halfSize}
        r={radius}
      />
    </svg>
  )
}

/**
 * Component designed to provide feedback helpful
 * on the current state of the operation that was triggered when
 * the button was clicked.
 * A common use case for the component is displaying whether a mutation
 * is pending, errored, or completed
 * The component can be used in a controlled (i.e the component handles tracking
 * whether the pending, error, or completed state should be shown based on
 * the provided async onClick handler) or is a controlled state (i.e. you
 * pass the props for the pending, completed, and error states)
 * If using in the uncontrolled context, the onClick handler should return a promise
 */
export const ProgressButton = ({
  buttonRootClassName,
  children: providedChildren,
  completed: providedCompleted = false,
  completedText = 'Saved',
  disabled = false,
  error: providedError,
  errorText = 'Error',
  iconLeft: providedIconLeft,
  pending: providedPending = false,
  pendingText = 'Loading',
  onClick,
  size = 'medium',
  ...buttonProps
}: ProgressButtonProps) => {
  const [completed, setCompleted] = useState(providedCompleted)
  const [error, setError] = useState(providedError)
  const [errorMessage, setErrorMessage] = useState()
  const [pending, setPending] = useState(providedPending)
  const iconLeft = useMemo(() => {
    if (pending) {
      return <LoadingIcon size={size} />
    } else if (error) {
      const iconSize = ButtonIconSizeLookup[size]
      return <ExclamationTriangleIcon size={iconSize as IconSizes} />
    } else if (completed) {
      const iconSize = ButtonIconSizeLookup[size]
      return <CheckmarkIcon size={iconSize as IconSizes} />
    } else {
      return providedIconLeft
    }
  }, [error, pending, completed, providedIconLeft, size])
  const children = useMemo(() => {
    if (pending) {
      return pendingText
    } else if (error) {
      return errorText
    } else if (completed) {
      return completedText
    } else {
      return providedChildren
    }
  }, [
    error,
    errorText,
    pending,
    completed,
    completedText,
    pendingText,
    providedChildren,
  ])
  const isControlled = providedError || providedCompleted || providedPending

  const handleClick = async event => {
    if (!!onClick) {
      if (!isControlled) {
        setPending(true)
        try {
          await onClick(event)
          setPending(false)
          setCompleted(true)
          await sleep(2000)
          setCompleted(false)
        } catch (err) {
          setPending(false)
          setError(true)
          const errorMessage =
            process.env.NODE_ENV === 'development'
              ? err?.toString()
              : 'Error! The request could not be completed.'
          setErrorMessage(errorMessage)
        }
      }
    }
  }

  return (
    <span className={cn(s.root, buttonRootClassName)}>
      <Button
        {...buttonProps}
        disabled={(disabled || pending) && !completed}
        iconLeft={iconLeft}
        onClick={handleClick}
        size={size}>
        {children}
      </Button>
      <Hidden visible={error && !!errorMessage}>
        <HelperText
          className={s.helperText}
          error={true}
          errorText={errorMessage}
        />
      </Hidden>
    </span>
  )
}
