import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const RecordIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 24 24"
      {...rest}>
      {title && <title>{title}</title>}
      <circle cx="12" cy="12" r="6" fill={color || 'currentColor'} />
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke={color || 'currentColor'}
        stroke-width="1.33"
      />
    </BaseSvgIcon>
  )
}
