import React from 'react'
import Link from 'next/link'
import cn from 'classnames'
import { Hidden } from 'components/design-system'
import { MenuItemText } from 'components/design-system/type-system'

import s from './styles.module.css'

interface MenuItemIconProps {
  className?: string
  children?: React.ReactElement
}

interface MenuItemProps {
  /**
   * Optional css class name to provide to the root of the component
   */
  className?: string
  children?: React.ReactNode
  /**
   * Whether the menu item should be displayed following dark mode
   * styling conventions
   */
  darkMode?: boolean
  /**
   * Whether the padding on the menu item should be constrained
   */
  dense?: boolean
  /**
   * Whether the menu item should not be clickable
   */
  disabled?: boolean
  /**
   * Optional CSS class name to pass to the menu item icons
   */
  iconClassName?: string
  /**
   * Optional icon to display to the left of the menu item text
   */
  iconLeft?: React.ReactElement
  /**
   * Optional icon to display to the right of the menu item text
   */
  iconRight?: React.ReactElement
  /**
   * Event handler that fires when the menu item is selected
   */
  onClick?: (event: React.MouseEvent) => void
  /**
   * Event handler that fires when the menu item is hovered on
   */
  onMouseOver?: (event: React.MouseEvent) => void
  /**
   * Event handler that fires when the menu item is hovered out
   */
  onMouseLeave?: (event: React.MouseEvent) => void
  /**
   * Whether the menu item should be displayed in a selected state
   */
  selected?: boolean
  /**
   * Optional CSS class name to provide to the root component for when
   * the item is in a selected state
   */
  selectedClassName?: string
  /**
   * Optional url to route the user to when they select on the menu item
   */
  to?: string
}

/**
 * Wrapper component that gets used with the `iconLeft` and
 * `iconRight` props
 * We have some special logic in place here to assign the size
 * medium to the provided icon
 * This helps ensure consistency and removes that burden from the dev
 */
const MenuItemIcon = ({ className, children }: MenuItemIconProps) => {
  const Icon = React.cloneElement(children, { size: 'medium' })
  return <span className={className}>{Icon}</span>
}

const MenuItemContent = React.forwardRef<HTMLLIElement, MenuItemProps>(
  function MenuItemContent(props, ref) {
    const {
      className,
      children,
      darkMode,
      dense,
      disabled,
      iconClassName,
      iconLeft,
      iconRight,
      onClick,
      onMouseOver,
      onMouseLeave,
      selected = false,
      selectedClassName,
      to,
    } = props

    return (
      <li
        className={cn(
          s.menuItemRoot,
          { [s.selected]: selected, [s.darkMode]: darkMode, [s.dense]: dense },
          className,
          selected && selectedClassName ? selectedClassName : undefined
        )}
        onClick={!disabled ? onClick : undefined}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        ref={ref}
        tabIndex={!to ? 0 : undefined}>
        <Hidden visible={!!iconLeft}>
          <MenuItemIcon className={cn(s.iconLeft, iconClassName)}>
            {iconLeft}
          </MenuItemIcon>
        </Hidden>
        <MenuItemText
          className={s.menuItemText}
          color={darkMode ? 'white' : 'textPrimary'}
          size="small">
          {children}
        </MenuItemText>
        <Hidden visible={!!iconRight}>
          <MenuItemIcon className={cn(s.iconRight, iconClassName)}>
            {iconRight}
          </MenuItemIcon>
        </Hidden>
      </li>
    )
  }
)

/**
 * Component used to render an opinionated, and stylized menu item
 * Should be used in conjunction the the <Menu /> and <MenuList />
 * components
 */
export const MenuItem = React.forwardRef<HTMLLIElement, MenuItemProps>(
  function MenuItem(props, ref) {
    const {
      className,
      children,
      darkMode = false,
      dense = false,
      disabled = false,
      iconClassName,
      iconLeft,
      iconRight,
      onClick,
      onMouseLeave,
      onMouseOver,
      selected,
      selectedClassName,
      to,
    } = props

    const content = (
      <MenuItemContent
        className={className}
        darkMode={darkMode}
        dense={dense}
        disabled={disabled}
        iconClassName={iconClassName}
        iconLeft={iconLeft}
        iconRight={iconRight}
        onClick={onClick}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        ref={ref}
        selected={selected}
        selectedClassName={selectedClassName}
        to={to}>
        {children}
      </MenuItemContent>
    )

    if (!!to && !disabled) {
      const isExternal = to?.startsWith('http')

      return (
        <Link
          href={to}
          className={cn(s.menuItemLink, { [s.darkMode]: darkMode })}
          target={isExternal ? '_blank' : undefined}
          rel={isExternal ? 'noopener' : undefined}>
          {content}
        </Link>
      )
    }

    return content
  }
)
