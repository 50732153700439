import React from 'react'
import cn from 'classnames'

import useSpacing from 'hooks/use-spacing'

import s from './styles.module.css'

export type ColorPalette =
  | 'textPrimary'
  | 'textSecondary'
  | 'textTertiary'
  | 'primary'
  | 'secondary'
  | 'white'
  | 'error'
  | 'success'
  | 'warning'

export interface BaseTypographyProps
  extends React.HTMLAttributes<HTMLOrSVGElement> {
  /**
   * Text alignment
   */
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  /**
   * If set to true, capitalizes the first letter of every word
   */
  capitalize?: boolean
  children: React.ReactNode
  /**
   * Optional CSS class name to pass to the root component
   */
  className?: string
  /**
   * Font color
   */
  color?: ColorPalette
  /**
   * Text display type
   */
  display?: 'block' | 'inline-block' | 'inline' | 'flex' | 'inline-flex'
  /**
   * If set to true, a standard bottom margin is
   * applied to the text
   */
  gutterBottom?: boolean
  /**
   * Number representing a rem value for the margin
   */
  m?: number
  /**
   * Number representing a rem value for the bottom margin
   */
  mb?: number
  /**
   * Number representing a rem value for the left margin
   */
  ml?: number
  /**
   * Number representing a rem value for the right margin
   */
  mr?: number
  /**
   * Number representing a rem value for the top margin
   */
  mt?: number
  /**
   * Number representing a rem value for the left and right margin
   */
  mx?: number
  /**
   * Number representing a rem value for the top and bottom margin
   */
  my?: number
  /**
   * Number representing a rem value for the padding
   */
  p?: number
  /**
   * Number representing a rem value for the bottom padding
   */
  pb?: number
  /**
   * Number representing a rem value for the left padding
   */
  pl?: number
  /**
   * Number representing a rem value for the right padding
   */
  pr?: number
  /**
   * Number representing a rem value for the top padding
   */
  pt?: number
  /**
   * Number representing a rem value for the left and right padding
   */
  px?: number
  /**
   * Number representing a rem value for the top and bottom padding
   */
  py?: number
  /**
   * Optional CSS properties to provide to the root element
   */
  style?: React.CSSProperties
  /**
   * Specify the HTML tag that should be used to render
   * the text. Useful for separating styling from semantics.
   */
  tag?: React.ElementType
  /**
   * Optional title attribute that should be passed to the root
   * component that will be displayed on hover
   */
  title?: string
  /**
   * Whether the text should be truncated with an ellipsis if the text
   * overflows the container
   */
  truncate?: boolean
  /**
   * Whether the text should be transformed to uppercase
   */
  uppercase?: boolean
  /**
   * Font weight for the text. Defaults to regular
   */
  weight?: 'regular' | 'medium' | 'semibold' | 'bold'
  /**
   * onClick action for the text. Defaults to none
   */
  onClick?: (event: any) => void | null
}

/**
 * The BaseTypography component represents the most basic building block for all
 * of our type system components. Our opinionated text components such as
 * <Display /> are built on top of it. Importantly, however, it can be used on
 * its own as well.
 */
export const BaseTypography = ({
  align = 'inherit',
  capitalize,
  color = 'textPrimary',
  children,
  className,
  display,
  gutterBottom,
  m,
  mb,
  ml,
  mr,
  mt,
  mx,
  my,
  p,
  pb,
  pl,
  pr,
  pt,
  px,
  py,
  style,
  tag = 'p',
  title,
  truncate,
  uppercase,
  weight = 'regular',
  onClick,
}: BaseTypographyProps) => {
  const generatedStyles = useSpacing(
    {
      m,
      mb,
      ml,
      mr,
      mt,
      mx,
      my,
      p,
      pb,
      pl,
      pr,
      pt,
      px,
      py,
    },
    style
  )
  const Component: React.ElementType = tag

  const mappedClassName = cn(className, s[weight], {
    [s[align]]: align,
    [s[color]]: color,
    [s[display]]: display,
    [s.gutterBottom]: gutterBottom,
    [s.capitalize]: capitalize,
    [s.truncate]: truncate,
    [s.uppercase]: uppercase,
  })

  return (
    <Component
      className={mappedClassName}
      style={generatedStyles}
      title={title}
      onClick={onClick}>
      {children}
    </Component>
  )
}
