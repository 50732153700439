import React from 'react'

export type IconSizes = 'tiny' | 'small' | 'medium' | 'large'
export interface BaseSvgIconProps extends React.SVGAttributes<SVGElement> {
  children?: React.ReactNode
  className?: string
  viewBox?: string
  size?: IconSizes
  title?: string
}

export const sizesLookup = {
  tiny: 10,
  small: 16,
  medium: 24,
  large: 32,
  xlarge: 48,
  '2xlarge': 64,
}

export const BaseSvgIcon = ({
  children,
  className,
  viewBox,
  size = 'medium',
  ...rest
}: BaseSvgIconProps) => {
  return (
    <svg
      className={className}
      width={sizesLookup[size]}
      height={sizesLookup[size]}
      viewBox={viewBox}
      {...rest}>
      {children}
    </svg>
  )
}
